<template>
  <v-container>
    <v-form ref="banner-form" @submit.prevent="formSubmit">
      <v-row>
        <v-col cols="12">
          <div class="page-title">{{ $route.meta.title }}</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card rounded="lg" class="pa-5">
            <v-card-text class="my-5">
              <v-tabs
                v-model="activeTab"
                color="primary"
                height="40px"
                show-arrows
                style="box-shadow: none !important; border-bottom: 1px solid rgb(242, 242, 242)"
              >
                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab> 網頁 </v-tab>
                <v-tab> App </v-tab>
              </v-tabs>

              <v-tabs-items v-model="activeTab">
                <v-tab-item>
                  <div class="my-5">
                    <v-row>
                      <v-col cols="12">
                        <v-btn
                          color="secondary"
                          class="px-6"
                          depressed
                          small
                          :disabled="isFetching"
                          @click="addWebsiteBanner"
                          >新增</v-btn
                        >
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <DraggableTable
                          :tableHeader="headers"
                          :data="websiteBanner"
                          @setOrder="updateWebsiteBannerOrder"
                          @dragTableAction="removeWebsiteBanner"
                        >
                          <template v-slot:[`banner`]="{ index }">
                            <FormButtonUpload
                              :uploadFile.sync="websiteBanner[index].image"
                              :fileInputKey="`websiteBannerUpload_${index}`"
                              :showUploadFileName="false"
                              :showUploadPreviewImg="true"
                              :imgMaxWidth="200"
                            ></FormButtonUpload>
                          </template>

                          <template v-slot:[`courseList`]="{ index }">
                            <v-select
                              color="indigo darken-4"
                              persistent-placeholder
                              :items="courseList"
                              v-model="websiteBanner[index].course_id"
                              item-text="text"
                              item-value="value"
                              outlined
                              hide-details="auto"
                              :disabled="isFetching"
                              no-data-text="沒有資料"
                              :dense="true"
                              :menu-props="{ bottom: true, offsetY: true }"
                            />
                          </template>
                        </DraggableTable>

                        <div class="error-msg mt-2">{{ websiteBannerError }}</div>
                      </v-col>
                    </v-row>
                  </div>
                </v-tab-item>

                <v-tab-item>
                  <div class="my-5">
                    <v-row>
                      <v-col cols="12">
                        <v-btn
                          color="secondary"
                          class="px-6"
                          depressed
                          small
                          :disabled="isFetching"
                          @click="addAppBanner"
                          >新增</v-btn
                        >
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <DraggableTable
                          :tableHeader="headers"
                          :data="appBanner"
                          @setOrder="updateAppBannerOrder"
                          @dragTableAction="removeAppBanner"
                        >
                          <template v-slot:[`banner`]="{ index }">
                            <FormButtonUpload
                              :uploadFile.sync="appBanner[index].image"
                              :fileInputKey="`appBannerUpload_${index}`"
                              :showUploadFileName="false"
                              :showUploadPreviewImg="true"
                              :imgMaxWidth="200"
                            ></FormButtonUpload>
                          </template>

                          <template v-slot:[`courseList`]="{ index }">
                            <v-select
                              color="indigo darken-4"
                              persistent-placeholder
                              :items="courseList"
                              v-model="appBanner[index].course_id"
                              item-text="text"
                              item-value="value"
                              outlined
                              hide-details="auto"
                              :disabled="isFetching"
                              no-data-text="沒有資料"
                              :dense="true"
                              :menu-props="{ bottom: true, offsetY: true }"
                            />
                          </template>
                        </DraggableTable>

                        <div class="error-msg mt-2">{{ appBannerError }}</div>
                      </v-col>
                    </v-row>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-btn color="primary" class="px-6" small depressed :disabled="isFetching" type="submit">儲存</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { mdiAlertCircleOutline } from '@mdi/js'
import BannerBtnUpload from '@/components/form/BannerBtnUpload'
import FormControl from '@/components/form/FormControl'
import FormDeleteBtn from '@/components/form/FormDeleteBtn'
import DraggableTable from '@/components/DraggableTable.vue'
import FormButtonUpload from '@/components/form/FormButtonUpload.vue'

export default {
  name: 'BannerDetail',
  components: {
    BannerBtnUpload,
    FormControl,
    FormDeleteBtn,
    DraggableTable,
    FormButtonUpload,
  },
  setup() {
    return {
      icons: {
        mdiAlertCircleOutline,
      },
    }
  },
  data() {
    return {
      id: -1,

      headers: [
        { text: '橫額', value: 'slot', slotName: 'banner', width: '35%', minWidth: '200px' },
        { text: '橫額到達頁面', value: 'slot', slotName: 'courseList', width: '45%', minWidth: '300px' },
        { text: '', value: 'deleteBtn', width: '10%' },
      ],
      websiteBanner: [],
      appBanner: [],
      removedWebsiteBannerList: [],
      removedAppBannerList: [],

      websiteBannerError: '',
      appBannerError: '',

      courseList: [],

      activeTab: 0,
    }
  },
  computed: {
    ...mapState({
      isFetching: state => state.request.isFetching,
    }),
  },

  methods: {
    ...mapActions(['setFetching'], 'request'),
    ...mapActions(['setDialogMessage', 'setShowDialog']),

    async getCourseData() {
      try {
        const payload = {
          verify_token: this.getAdminToken(),
        }

        const result = await this.$XHR.api('cms_get_course', payload)
        this.$func.log('-----Get Course-----')
        this.$func.log(result)

        this.courseList = result.data.map(obj => {
          return { text: obj.course_name, value: obj.id }
        })
      } catch (error) {
        this.$func.log('-----Get Course fail-----')
        this.$func.log(error)

        if (error.data === 'admin verification fail') {
          this.forceLogout()
        }

        this.courseList = []
      }
    },

    async getBannerData() {
      try {
        const payload = {
          verify_token: this.getAdminToken(),
        }

        const result = await this.$XHR.api('cms_get_promotion_banner', payload)
        this.$func.log('-----Get Promotion Banner-----')
        this.$func.log(result)

        this.websiteBanner = JSON.parse(JSON.stringify(result.website))
        this.appBanner = JSON.parse(JSON.stringify(result.app))

        this.id = parseInt(result.id)
      } catch (error) {
        this.$func.log('-----Get Promotion Banner-----')
        this.$func.log(error)

        if (error.data === 'admin verification fail') {
          this.forceLogout()
        }

        this.websiteBanner = []
        this.appBanner = []
      } finally {
        this.setFetching(false)
        this.$store.dispatch('toggleLoadingPage', false)
      }
    },

    addWebsiteBanner() {
      this.websiteBanner.push({ image: '', course_id: '' })
    },

    updateWebsiteBannerOrder(data) {
      this.websiteBanner = data
    },

    removeWebsiteBanner(type, index) {
      if (type === 'delete') {
        this.removedWebsiteBannerList.push(this.websiteBanner[index].image)
        this.websiteBanner.splice(index, 1)
      }
    },

    addAppBanner() {
      this.appBanner.push({ image: '', course_id: '' })
    },

    updateAppBannerOrder(data) {
      this.appBanner = data
    },

    removeAppBanner(type, index) {
      if (type === 'delete') {
        this.removedAppBannerList.push(this.appBanner[index].image)
        this.appBanner.splice(index, 1)
      }
    },

    async removeUnusedImage() {
      let removeList = []
      if (this.$store.getters.removedImageList.length > 0) {
        removeList = removeList.concat(this.$store.getters.removedImageList)
      }

      if (this.submitSuccess) {
        removeList = removeList.concat(this.removedWebsiteBannerList)
        removeList = removeList.concat(this.removedAppBannerList)

        // don't remove saved image
        const websiteImg = this.websiteBanner.map(obj => {
          return obj.image
        })

        const appImg = this.appBanner.map(obj => {
          return obj.image
        })

        for (let i = 0; i < websiteImg.length; i++) {
          const index = removeList.indexOf(websiteImg[i])

          if (index !== -1) {
            removeList.splice(index, 1)
          }
        }

        for (let i = 0; i < appImg.length; i++) {
          const index = removeList.indexOf(appImg[i])

          if (index !== -1) {
            removeList.splice(index, 1)
          }
        }
      }

      if (removeList.length > 0) {
        const removeImage = await this.$XHR.api('cms_remove_media', {
          verify_token: this.getAdminToken(),
          remove_image_list: removeList,
        })

        this.$func.log('--- remove image success ---')
        this.$func.log(removeImage)
      }

      this.$store.dispatch('resetRemovedImage', [])
    },

    async formSubmit() {
      if (!this.isFetching) {
        this.setFetching(true)

        this.websiteBannerError = ''
        this.appBannerError = ''

        const isValid = this.$refs['banner-form'].validate()

        for (let i = 0; i < this.websiteBanner.length; i++) {
          if (!this.$validate.DataValid(this.websiteBanner[i].image)) {
            this.websiteBannerError = '請上傳橫額'
            break
          }
        }

        for (let i = 0; i < this.appBanner.length; i++) {
          if (!this.$validate.DataValid(this.appBanner[i].image)) {
            this.appBannerError = '請上傳橫額'
            break
          }
        }

        if (
          !isValid ||
          this.$validate.DataValid(this.websiteBannerError) ||
          this.$validate.DataValid(this.appBannerError)
        ) {
          if (this.$validate.DataValid(this.websiteBannerError)) {
            this.activeTab = 0
          } else if (this.$validate.DataValid(this.appBannerError)) {
            this.activeTab = 1
          }

          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: '請先填滿所有必填項目',
            type: 'error',
            refresh: false,
            redirect: '',
          })
          this.setFetching(false)
          return
        }

        const payload = {
          id: parseInt(this.id),
          verify_token: this.getAdminToken(),
          data_type: 'promotion_banner_data',
          status: 'active',
          data_status: 'active',
          website: this.websiteBanner.map(obj => {
            return { image: obj.image, course_id: parseInt(obj.course_id) }
          }),
          app: this.appBanner.map(obj => {
            return { image: obj.image, course_id: parseInt(obj.course_id) }
          }),
        }

        try {
          const result = await this.$XHR.api('cms_update_promotion_banner', payload)
          this.$func.log('-----Save Promotion Banner Success-----')
          this.$func.log(result)

          this.submitSuccess = true

          this.removeUnusedImage()

          this.setDialogMessage({
            message: '儲存成功',
            isError: false,
            returnLink: null,
            refresh: true,
          })

          this.setShowDialog(true)
        } catch (error) {
          this.$func.log('-----Save Promotion Banner Fail-----')
          this.$func.log(error)

          this.submitSuccess = false

          if (error.data === 'admin verification fail') {
            this.forceLogout()
          } else {
            let msg = '儲存失敗'

            switch (error.data) {
              case 'promotion banner does not exist':
                msg = '資料不存在'
                break
            }

            this.setDialogMessage({
              message: msg,
              isError: false,
              returnLink: null,
            })
            this.setShowDialog(true)
          }
        } finally {
          this.setFetching(false)
        }
      }
    },
  },
  async created() {
    this.$store.dispatch('toggleLoadingPage', true)
    const check = await this.checkUserData()
    if (check) {
      this.setFetching(true)
      this.getCourseData()
      this.getBannerData()
    }
  },
  async beforeDestroy() {
    this.removeUnusedImage()
  },
}
</script>

<style lang="scss" scoped>
.img-preview {
  background-color: #e0e0e0 !important;
}
</style>