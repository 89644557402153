var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-form',{ref:"banner-form",on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"page-title"},[_vm._v(_vm._s(_vm.$route.meta.title))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-5",attrs:{"rounded":"lg"}},[_c('v-card-text',{staticClass:"my-5"},[_c('v-tabs',{staticStyle:{"box-shadow":"none !important","border-bottom":"1px solid rgb(242, 242, 242)"},attrs:{"color":"primary","height":"40px","show-arrows":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_c('v-tab',[_vm._v(" 網頁 ")]),_c('v-tab',[_vm._v(" App ")])],1),_c('v-tabs-items',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab-item',[_c('div',{staticClass:"my-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"px-6",attrs:{"color":"secondary","depressed":"","small":"","disabled":_vm.isFetching},on:{"click":_vm.addWebsiteBanner}},[_vm._v("新增")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('DraggableTable',{attrs:{"tableHeader":_vm.headers,"data":_vm.websiteBanner},on:{"setOrder":_vm.updateWebsiteBannerOrder,"dragTableAction":_vm.removeWebsiteBanner},scopedSlots:_vm._u([{key:"banner",fn:function(ref){
var index = ref.index;
return [_c('FormButtonUpload',{attrs:{"uploadFile":_vm.websiteBanner[index].image,"fileInputKey":("websiteBannerUpload_" + index),"showUploadFileName":false,"showUploadPreviewImg":true,"imgMaxWidth":200},on:{"update:uploadFile":function($event){return _vm.$set(_vm.websiteBanner[index], "image", $event)},"update:upload-file":function($event){return _vm.$set(_vm.websiteBanner[index], "image", $event)}}})]}},{key:"courseList",fn:function(ref){
var index = ref.index;
return [_c('v-select',{attrs:{"color":"indigo darken-4","persistent-placeholder":"","items":_vm.courseList,"item-text":"text","item-value":"value","outlined":"","hide-details":"auto","disabled":_vm.isFetching,"no-data-text":"沒有資料","dense":true,"menu-props":{ bottom: true, offsetY: true }},model:{value:(_vm.websiteBanner[index].course_id),callback:function ($$v) {_vm.$set(_vm.websiteBanner[index], "course_id", $$v)},expression:"websiteBanner[index].course_id"}})]}}],null,true)}),_c('div',{staticClass:"error-msg mt-2"},[_vm._v(_vm._s(_vm.websiteBannerError))])],1)],1)],1)]),_c('v-tab-item',[_c('div',{staticClass:"my-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"px-6",attrs:{"color":"secondary","depressed":"","small":"","disabled":_vm.isFetching},on:{"click":_vm.addAppBanner}},[_vm._v("新增")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('DraggableTable',{attrs:{"tableHeader":_vm.headers,"data":_vm.appBanner},on:{"setOrder":_vm.updateAppBannerOrder,"dragTableAction":_vm.removeAppBanner},scopedSlots:_vm._u([{key:"banner",fn:function(ref){
var index = ref.index;
return [_c('FormButtonUpload',{attrs:{"uploadFile":_vm.appBanner[index].image,"fileInputKey":("appBannerUpload_" + index),"showUploadFileName":false,"showUploadPreviewImg":true,"imgMaxWidth":200},on:{"update:uploadFile":function($event){return _vm.$set(_vm.appBanner[index], "image", $event)},"update:upload-file":function($event){return _vm.$set(_vm.appBanner[index], "image", $event)}}})]}},{key:"courseList",fn:function(ref){
var index = ref.index;
return [_c('v-select',{attrs:{"color":"indigo darken-4","persistent-placeholder":"","items":_vm.courseList,"item-text":"text","item-value":"value","outlined":"","hide-details":"auto","disabled":_vm.isFetching,"no-data-text":"沒有資料","dense":true,"menu-props":{ bottom: true, offsetY: true }},model:{value:(_vm.appBanner[index].course_id),callback:function ($$v) {_vm.$set(_vm.appBanner[index], "course_id", $$v)},expression:"appBanner[index].course_id"}})]}}],null,true)}),_c('div',{staticClass:"error-msg mt-2"},[_vm._v(_vm._s(_vm.appBannerError))])],1)],1)],1)])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"px-6",attrs:{"color":"primary","small":"","depressed":"","disabled":_vm.isFetching,"type":"submit"}},[_vm._v("儲存")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }