<template>
   <v-row>
      <v-col cols="12" v-show="false">
         <v-file-input
            ref="image"
            hide-details="auto"
            persistent-placeholder
            :rules="rules.uploadRules"
            outlined
            prepend-icon=""
            append-icon=""
            @change="onFilePicked"
            accept="image/png, image/jpeg, image/bmp, application/pdf"
            :disabled="isFetching || disabled" />
      </v-col>
      <v-col cols="12">
         <v-btn class="form-btn ma-2 m-auto px-6" 
            color="primary" 
            @click="handleFileImport" 
            small
            :disabled="isFetching || disabled">
            {{btnlabel || "上載"}}
         </v-btn>
      </v-col>
   </v-row>

</template>

<script>
import { mapState } from "vuex";

export default {
   name: 'BannerButtonUpload',
   props: ["value", "btnlabel", "disabled"],
   data() {
      return {         
         rules: {
            uploadRules: [
               v => this.required ? (!!v && v.size < 2000000 || 'File size should be less than 2 MB') : true,
            ]
         }
      }
   },
   computed: {
      ...mapState({
         state: (state) => state,
         isFetching: (state) => state.request.isFetching,
         isSuccess: (state) => state.request.isSuccess,
         isFail: (state) => state.request.isFail,
      }),
   },
   methods: {
      handleFileImport() {
         this.$refs.image.$refs.input.click();
      },
      onFilePicked(file) {
         if (file != null) {
            const imageName = file.name
            if (imageName.lastIndexOf('.') <= 0) {
               return
            }
            this.$emit('input', file);
         } else {
            this.$emit('input', null);
         }
      }
   }
}
</script>

<style lang="scss" scoped>
.form-text {
   // border: 1px solid #eee;
   position: relative;
   padding-left: 11px;
   min-height: 55px;

   .form-label {
      background: #fff;
      position: absolute;
      top: -11px;
      padding-inline-start: 2px;
      padding-inline-end: 2px;
   }

   span.text {
      position: absolute;
      bottom: 16px;
   }

   div.text {
      position: absolute;
      bottom: 0;
   }

   &.bordered {
      border-collapse: collapse;
      border-color: rgba(94, 86, 105, 0.22);
      border-style: solid;
      border-width: 1px;
      border-radius: 5px;
   }
}
</style>